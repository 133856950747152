<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" data-cy="form-matched"/>
    <matched-approval-form :is-show="isShowSettleDetails" :details="details" :merchantCodes="listOfMerchantCodes" v-on:close-dialog="closeDialog" v-on:for-approval-status="updateBatchStatusForApproval(details.item)" data-cy="form-matched-approval"/>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Matched Transactions</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-1">
            <v-col cols="12" md="5">
                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="5">
                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                        <v-btn text color="primary" @click="clearToDate()">Clear
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col class="d-flex" cols="12" md="3">
                <v-autocomplete
                    clearable chips
                    :items="merchantCodes" 
                    label="Merchant" 
                    v-model="filteredTransaction.merchantCode" 
                    return-object
                    data-cy="merchant-code-list"
                    @change="searchMerchantCode">
                </v-autocomplete>
            </v-col>
            <v-col class="d-flex" cols="12" md="3">
                <v-text-field class="px-4" label="Payconnect Reference" single-line @change="searchPayConnectRef" data-cy="payconnect-reference-text"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="3">
                <v-text-field class="px-4" label="Merchant Reference" single-line @change="searchMerchantRef" data-cy="merchant-reference-text"></v-text-field>
            </v-col>
            <v-col class="d-flex mt-4" cols="12" md="2">
                <v-btn :disabled='isDisabled' class="ml-5" color=success @click="queryData(true)" data-cy="action-button-search">
                    <v-icon dark left> mdi-magnify</v-icon>Search
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex mt-4" cols="12" md="2">
                <v-flex xs12>
                    <v-btn :disabled='isCreateDisabled' class="ml-5" color=success @click="download()" data-cy="action-button-export">Export</v-btn>
                </v-flex>
                <v-flex xs12>
                    <v-btn :disabled='isCreateDisabled' class="ml-5" color=success @click="forApprovalTransactions(details)" data-cy="action-button-for-approval">Create Settlement Batch</v-btn>
                </v-flex>
            </v-col>
        </v-row>

         <v-row >
            <v-col cols="12" md="3">    
                <v-switch 
                    class="ml-5"
                    v-model="isSettled"
                    @change="queryData(true)"
                    :label="`${isSettled ? 'Settled' : 'not Settled'}`"
                ></v-switch>
            </v-col> 
        </v-row>   

        <v-row >
            <v-col cols="12" md="6">    
                <strong class="mr-2">Total Count: </strong>   
                <span class="price-content">{{commarized(totalCount)}}</span>
            </v-col> 
            <v-col cols="12" md="4">    
                 <strong class="mr-2">Total Amount: </strong>
                <span class="price-content">{{commarized(totalAmount)}}</span>
            </v-col>
        </v-row>     
    </v-card>

    <!-- <div class="text-uppercase text-bold">id selected: {{selected}}</div> -->
    <!-- <div class="col-md-6 text-right">
        <strong>Total Amount:</strong> <span class="price-content">{{totalAmount}}</span>
    </div> -->

    <template>
        <v-card>
            <v-data-table :hide-default-header="true" :headers="headers" :items="displayMatchedTransactions" :page="page" :pageCount="numberOfPages" :options.sync="options" class="elevation-2 mt-4 px-4" :server-items-length="totalRecords" 
            :footer-props="{	
                'items-per-page-options': [10]	
                }"	
            >>
                <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr :colspan="headers.length">
                                <th v-for="header in headers" :key="header.text">
                                    <span class="d-flex justify-center">{{header.text}}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody v-if="items.length > 0">
                            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.payconnectReference }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.amount }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.merchantReference }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.transactionDate }} </span></td>
                                <td>
                                    <span class="d-flex justify-center">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="viewTransaction(item)">
                                                <v-icon dark medium>mdi-magnify-plus</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>details</span> 
                                        </v-tooltip>    
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <td :colspan="headers.length"> <span class="d-flex justify-center mt-3 mb-3"> No results found. </span></td>
                        </tbody>
                    </template>
            </v-data-table>           
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import MatchedApprovalForm from '@/views/components/matchedApprovalForm.vue'
import moment from 'moment';

export default {
    name: 'Unmatched',
    components: {
        Loading,
        DetailsForm,
        MatchedApprovalForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
        isDisabled() {
           if(!(this.filteredTransaction.merchantCode && this.toDateVal)) {
               return this.buttonDisabled = true
           } else {
               return this.buttonDisabled = false
           }
        },
        isCreateDisabled() {
            if(!(this.totalAmount && this.totalCount && this.filteredTransaction.merchantCode && this.toDateVal && this.isSettled === false && this.loading === false)) {
               return this.buttonDisabled = true
           } else {
               return this.buttonDisabled = false
           }
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'ID',
                    value: 'id',
                    sortable: false
                },
                {
                    text: 'Merchant',
                    value: 'merchantCode',
                    sortable: false
                },
                {
                    text: 'Reference Number',
                    value: 'payconnectReference',
                    sortable: false
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    sortable: false
                },
                {
                    text: 'Merchant Ref. Num.',
                    value: 'merchantReference',
                    sortable: false
                },
                {
                    text: 'Date',
                    value: 'transactionDate',
                    sortable: false
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            listOfMerchantCodes: [],
            merchantCodes: this.listOfMerchantCodes ?? [],
            loading: true,
            page: 1,
            itemsPerPage: 10,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMatchedTransactions: 0,
            totalRecords: 0,
            itemsPerPage: 10,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                selectedMerchant: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            totalAmount: "0",
            totalCount: "0",
            displayMatchedTransactions: [],
            remainderMatchedTransactions: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            isShowSettleDetails: false,
            isSettled: false,
            showFormDialog: false,
            isHtml: false,
            selected: [],
	    	selectAll: false,
            totalCount:'',
        }
    },
    async mounted() {
        this.setDefaultDateRange()
    },
    watch: {
        options: {
            handler() {
                this.loadingDialog = false;
                this.queryData(false);
                this.setData();
            },
        },
        listOfMerchantCodes: {
            handler(value) {
                this.merchantCodes = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getMerchantCode()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfMerchantCodes = listResponse.merchantCodes
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            this.loadingDialog = true
            this.loadingMessage = `Searching, please wait...`
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var limit = itemsPerPage	
                if(limit < 0)	
                    limit = this.totalRecords	
                // var matchedTransactionList = undefined
                
                const parameters = {
                    merchantCode: this.filteredTransaction ? this.filteredTransaction.merchantCode ?? '' : '',
                    payconnectReference: this.filteredTransaction.payconnectReference,
                    merchantReference: this.filteredTransaction.merchantReference,
                    isSettled: this.isSettled,
                    dateFrom: this.fromDateVal, 
                    dateTo: this.toDateVal,
                    pageNo: pageNumber,
                    pageSize: limit
                }

                const matchedTransactionList = await API.getMatchedTransactions(parameters)
                if (!matchedTransactionList || matchedTransactionList.error) {
                    console.log(`${matchedTransactionList.error}`)
                } else {
                    var filteredList = matchedTransactionList.payconnectTransactions
                    this.totalRecords = matchedTransactionList.totalCount

                    this.totalAmount = matchedTransactionList.totalAmount ?? 0.00
                    this.totalCount = matchedTransactionList.totalCount

                    if (page > 1 && this.remainderMatchedTransactions.length > 0) {
                        filteredList = this.remainderMatchedTransactions.concat(filteredList)
                    }
                    this.totalMatchedTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMatchedTransactions = filteredList.slice(0, end)
                    this.remainderMatchedTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMatchedTransactions / itemsPerPage

                    parameters.pageNo = this.totalMatchedTransactions	
                    parameters.pageSize = 1
                    
                    const hasMoreResponse = matchedTransactionList
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.payconnectTransactions
                        if(hasMoreList.length > 0) {
                        // this.totalMatchedTransactions = Math.floor(this.totalMatchedTransactions/10) * 10 + 1
                        this.totalMatchedTransactions = this.totalMatchedTransactions + 1
                        this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                    this.page = page	
                    this.itemsPerPage = itemsPerPage
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
            this.loadingDialog = false
        },

        searchMerchantCode: async function(val) {
            this.filteredTransaction.merchantCode = val
            // this.queryData(true)
        },
        searchPayConnectRef(val) {
            this.filteredTransaction.payconnectReference = val
            // this.queryData(true)
        },
        searchMerchantRef(val) {
            this.filteredTransaction.merchantReference = val
            // this.queryData(true)
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.fromDateVal = this.getToday();
            this.toDateVal = this.getToday();
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.fromDateVal)
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
                this.isShowSettleDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
                this.loadingFinished = false;
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async download() {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.loadingMessage = `Downloading CSV please wait...`
            const downloadResponse = await API.exportMatchedTransaction(this.filteredTransaction.merchantCode,this.fromDateVal,this.toDateVal)
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.item = transaction
            this.isShowDetails = true
        },
        forApprovalTransactions(details) {
            console.log(details)
            this.details.selectedMerchant = this.filteredTransaction.merchantCode
            this.details.selectedFromDate = this.fromDateVal
            this.details.selectedToDate = this.toDateVal
            this.details.totalSelectedAmount = this.totalAmount
            this.details.totalSelectedCount = this.totalCount
            this.isShowSettleDetails = true
        },
        updateBatchStatusForApproval(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Creating settlement batch please wait...`
                setTimeout(async () => {
                    const createResponse = await API.updateSettlementBatchForApproval(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else if(!createResponse || createResponse.message){
                        this.loadingMessage = createResponse.message
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    } else {
                        this.loadingMessage = `Successfully updated settlement batch!`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        commarized(value) {
            var numeral = require("numeral");
            return numeral(value).format("0,0[.]00");
        },
    }
};
</script>


<style scoped>

</style>

